import type { Ref } from 'vue';

export interface IService {
		title?:string;
    heading?: string;
    text: string;
		productUrl?: string;
}
interface IUseServicePopup{
    isServicePopupOpen:Ref<boolean>;
    isSuccessShown: Ref<boolean>
    currentService:Ref<IService|undefined>;
    openServicePopup:(product?:IService) => void;
    onCloseServicePopup:()=>void;
    onToggleSuccessSection: (option: boolean) => void;
}

export const useServicePopup = ():IUseServicePopup => {
  const isServicePopupOpen = useState<boolean>('isServiceModalOpen', () => false);
  const isSuccessShown = useState<boolean>('isSuccessShown', () => false);
  const currentService = useState < IService | undefined >('currentService', () => undefined);
  const openServicePopup = (service?:IService) => {
    if (service) {
      currentService.value = service;
    }
    isServicePopupOpen.value = true;
  };
  const onCloseServicePopup = () => {
    isServicePopupOpen.value = false;
  };

  const onToggleSuccessSection = (option: boolean) => {
    isSuccessShown.value = option;
  };

  return {
    isServicePopupOpen,
    currentService,
    isSuccessShown,
    openServicePopup,
    onCloseServicePopup,
    onToggleSuccessSection
  };
};
