<template>
  <div class="header-social-wrapper">
    <div class="header-social-links">
      <a
        :href="getCleanedPhoneForHref(phone)"
        class="btn primary footer-social-btn"
      >ПОЗВОНИТЬ НАМ</a>
      <a
        v-for="item in socialList"
        :key="item.label"
        :href="item.label === 'telegram' ? getTelegramPhoneForHref(item.value) : getWhatsAppPhoneForHref(item.value)"
        class="btn primary btn-link"
        target="_blank"
      >
        <img
          :src="item.img"
          alt="img"
        >
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getCleanedPhoneForHref, getTelegramPhoneForHref, getWhatsAppPhoneForHref } from '~/utils/index';
import { useAsset } from '~/composables/useAssets';

const { telegram, whatsapp } = defineProps<{
  telegram: string;
  whatsapp: string;
  phone: string;
}>();

interface IContactList {
  img: string;
  value: string;
  isShow: boolean;
  label: string;
}

const socialList: IContactList[] = [
  {
    img: useAsset('img/svg/social/telegram.svg'),
    value: telegram,
    isShow: Boolean(telegram),
    label: 'telegram'
  },
  {
    img: useAsset('img/svg/social/whatsapp.svg'),
    value: whatsapp,
    isShow: Boolean(whatsapp),
    label: 'whatsapp'
  }
];

</script>

<style lang="scss" scoped>
.header-social-wrapper {
  display: flex;
  gap: 28px;
  align-items: center;
}

.header-social-phone a {
  color: var(--text-primary);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.22px;
  text-transform: uppercase;
}

.header-social-links {
  display: flex;
  align-items: center;
  gap: 6px;
}

.btn-link {
  padding: 0;
}

@media (max-width: 640px) {
  .header-social-wrapper {
    width: 100%;
  }

  .header-social-links {
    width: 100%;
  }

  .footer-social-btn {
    flex: 1;
  }
}
</style>
