import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFormBlock, LazyGrossBoldTextBlock, LazyGrossContactsBlock, LazyGrossFeaturesBlock, LazyGrossMainAdvantagesBlock, LazyGrossMainCategoriesBlock, LazyGrossMainDeliveryBlock, LazyGrossMainHistoryBlock, LazyGrossMainProductionComplexBlock, LazyGrossMainSliderBlock, LazyGrossQuoteBlock, LazyGrossTwoColumnTextBlock, LazyImageBlock, LazyImageGalleryBlock, LazyImageSliderBlock, LazyPopupBlock, LazyRmmCertificatesBlock, LazyRmmContactsBlock, LazyLongContactItem, LazyShortContactItem, LazyRmmDocumentsBlock, LazyDocumentsBlockItem, LazyRmmHeaderBlock, LazyRmmOurPriciplesBlock, LazyPricipleItem, LazyRmmQualityControlBlock, LazyRmmServiceList, LazyRmmServicesMaterialsListBlock, LazyMaterialsList, LazyServicesList, LazyRmmTechnicalOpportunitesBlock, LazyOpportunitiesItem, LazyRmmWhyUs, LazyWhyUsItem, LazyStoresBlock, LazyMainLayout, LazyCmsBlockMixin, LazyCmsPage, LazyCarouselBlock, LazyCkEditorBlock, LazyContainerBlock, LazyGridBlock, LazyHeadingBlock, LazySectionBlock, LazySpecialBlock, LazyTwoColumnsBlock, LazyLayoutPlaceholder, LazyHeaderNavItem, LazyTestHeaderNav, LazyPopupGallery, LazySearchInput, LazyLazySearchDropdown, LazySearchInputBody, LazySearchInputTop, LazyArticles, LazyBrands, LazyCategories, LazyProductCard, LazyProducts, LazyService, LazyVideos, LazyComponent, LazyAddToCardBtn, LazyProductCardTemplate, LazyProductPageTemplate, LazyPagination } from '#components'
const lazyGlobalComponents = [
  ["FormBlock", LazyFormBlock],
["GrossBoldTextBlock", LazyGrossBoldTextBlock],
["GrossContactsBlock", LazyGrossContactsBlock],
["GrossFeaturesBlock", LazyGrossFeaturesBlock],
["GrossMainAdvantagesBlock", LazyGrossMainAdvantagesBlock],
["GrossMainCategoriesBlock", LazyGrossMainCategoriesBlock],
["GrossMainDeliveryBlock", LazyGrossMainDeliveryBlock],
["GrossMainHistoryBlock", LazyGrossMainHistoryBlock],
["GrossMainProductionComplexBlock", LazyGrossMainProductionComplexBlock],
["GrossMainSliderBlock", LazyGrossMainSliderBlock],
["GrossQuoteBlock", LazyGrossQuoteBlock],
["GrossTwoColumnTextBlock", LazyGrossTwoColumnTextBlock],
["ImageBlock", LazyImageBlock],
["ImageGalleryBlock", LazyImageGalleryBlock],
["ImageSliderBlock", LazyImageSliderBlock],
["PopupBlock", LazyPopupBlock],
["RmmCertificatesBlock", LazyRmmCertificatesBlock],
["RmmContactsBlock", LazyRmmContactsBlock],
["LongContactItem", LazyLongContactItem],
["ShortContactItem", LazyShortContactItem],
["RmmDocumentsBlock", LazyRmmDocumentsBlock],
["DocumentsBlockItem", LazyDocumentsBlockItem],
["RmmHeaderBlock", LazyRmmHeaderBlock],
["RmmOurPriciplesBlock", LazyRmmOurPriciplesBlock],
["PricipleItem", LazyPricipleItem],
["RmmQualityControlBlock", LazyRmmQualityControlBlock],
["RmmServiceList", LazyRmmServiceList],
["RmmServicesMaterialsListBlock", LazyRmmServicesMaterialsListBlock],
["MaterialsList", LazyMaterialsList],
["ServicesList", LazyServicesList],
["RmmTechnicalOpportunitesBlock", LazyRmmTechnicalOpportunitesBlock],
["OpportunitiesItem", LazyOpportunitiesItem],
["RmmWhyUs", LazyRmmWhyUs],
["WhyUsItem", LazyWhyUsItem],
["StoresBlock", LazyStoresBlock],
["MainLayout", LazyMainLayout],
["CmsBlockMixin", LazyCmsBlockMixin],
["CmsPage", LazyCmsPage],
["CarouselBlock", LazyCarouselBlock],
["CkEditorBlock", LazyCkEditorBlock],
["ContainerBlock", LazyContainerBlock],
["GridBlock", LazyGridBlock],
["HeadingBlock", LazyHeadingBlock],
["SectionBlock", LazySectionBlock],
["SpecialBlock", LazySpecialBlock],
["TwoColumnsBlock", LazyTwoColumnsBlock],
["LayoutPlaceholder", LazyLayoutPlaceholder],
["HeaderNavItem", LazyHeaderNavItem],
["TestHeaderNav", LazyTestHeaderNav],
["PopupGallery", LazyPopupGallery],
["SearchInput", LazySearchInput],
["LazySearchDropdown", LazyLazySearchDropdown],
["SearchInputBody", LazySearchInputBody],
["SearchInputTop", LazySearchInputTop],
["Articles", LazyArticles],
["Brands", LazyBrands],
["Categories", LazyCategories],
["ProductCard", LazyProductCard],
["Products", LazyProducts],
["Service", LazyService],
["Videos", LazyVideos],
["Component", LazyComponent],
["AddToCardBtn", LazyAddToCardBtn],
["ProductCardTemplate", LazyProductCardTemplate],
["ProductPageTemplate", LazyProductPageTemplate],
["Pagination", LazyPagination],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
