import type { Ref } from 'vue';
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';

interface ICustomProduct extends IProduct {
  image_id: number
}
interface IUseProductPopup{
    isProductPopupOpen:Ref<boolean>;
    currentProduct:Ref<ICustomProduct|undefined>;
    openProductPopup:(product:ICustomProduct, ymSendFormValue?: string) => void;
    onCloseProductPopup:()=>void;
    ymSendForm: Ref<string>;
}

export const useProductPopup = ():IUseProductPopup => {
  const isProductPopupOpen = useState<boolean>('isFastOrderModalOpen', () => false);
  const currentProduct = useState < ICustomProduct | undefined >('orderCurrentProduct', () => undefined);
  const ymSendForm = useState<string>('ymSendForm', () => '');
  const openProductPopup = (product:ICustomProduct, ymSendFormValue?: string) => {
    currentProduct.value = product;
    isProductPopupOpen.value = true;

    if (ymSendFormValue) {
      ymSendForm.value = ymSendFormValue;
    }
  };
  const onCloseProductPopup = () => {
    isProductPopupOpen.value = false;
    ymSendForm.value = '';
  };

  return {
    isProductPopupOpen,
    currentProduct,
    ymSendForm,
    openProductPopup,
    onCloseProductPopup
  };
};
