<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-top">
        <NuxtLink
          to="/"
          class="logo-link"
        >
          <img
            src="~/assets/img/png/header/logo.png"
            alt="logo"
          >
        </NuxtLink>

        <div class="footer-top-separator"></div>

        <div class="footer-top-description">
          Производитель нестандартного оборудования и запасных частей для
          предприятий металлургии, энергетики, нефтегазоперерабатывающей
          промышленностей и транспортного машиностроения
        </div>
        <div class="footer-top-separator"></div>

        <FooterContacts
          :mail="varsList?.['email'] || ''"
          :address="configList?.address || ''"
          :phone="configList?.phone || ''"
        />
      </div>
      <img
        src="~/assets/img/svg/footer/separator.svg"
        alt="separator"
        class="footer-common-separator"
      >
      <div class="footer-middle">
        <FooterNav
          :menus="menu|| []"
        />
        <FooterSocials
          :telegram="configList?.telegram || ''"
          :whatsapp="configList?.whatsapp || ''"
          :phone="configList?.phone || ''"
        />
      </div>
      <div class="footer-bottom">
        <div class="footer-bottom-links">
          <NuxtLink
            target="_blank"
            to="/politika-konfidencialnosti"
          >
            Политика конфиденциальности
          </NuxtLink>
        </div>
        <a
          rel="nofollow"
          href="https://sitisit.ru/"
          target="_blank"
          class="sitis-support"
        >
          <span class="footer-info-text">Сделано в Ситис</span>
          <img
            alt="sitis"
            src="~/assets/img/svg/footer/logo-sitis.svg"
          >
        </a>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { storeToRefs } from 'pinia';
import FooterNav from '~/components/common/Footer/FooterNav.vue';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import FooterContacts from '~/components/common/Footer/FooterContacts.vue';
import FooterSocials from '~/components/common/Footer/FooterSocials.vue';

const $viewport = useViewport();

const cmsStore = cmsContentStore();
const { menu } = storeToRefs(cmsStore);
const appConfigStore = useAppConfigStore();
const {
  configList,
  varsList
} = storeToRefs(appConfigStore);

</script>

<style lang="scss">
.footer {
  padding: 24px 0;
  background: var(--dark-gray);
}

.footer-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 40px;
}

.footer-top-separator {
  width: 1px;
  background: rgb(255 255 225 / 20%);
  height: 76px;
  margin: 0 36px;
}

.footer-top-description {
  flex: 1;
  color: var(--text-white);
  font-size: 20px;
}

.logo-link {
  width: 267px;

  img {
    width: 267px;
  }
}

.footer-middle {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
}

.footer-bottom-links {
  display: flex;
  gap: 40px;

  & > * {
    color: rgb(255 255 255 / 50%);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 155.52%; /* 24.883px */
    letter-spacing: -0.32px;
    text-decoration-line: underline;
    transition: color .2s linear;

    &:hover {
      color: var(--primary);
    }
  }
}

.sitis-support {
  display: flex;
  align-items: center;
  gap: 7px;

  span {
    color: var(--text-white);
    font-size: 16px;
  }
}

@media(max-width: 1023px) {
  .footer-middle {
    flex-direction: column;
    gap: 20px;
  }

  .footer-top {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .footer-top-separator {
    display: none;
  }

  .footer-socials-wrapper {
    align-items: center;
  }

  .footer-top-description {
    text-align: center;
  }
}

@media(max-width: 640px) {
  .footer-top {
    align-items: flex-start;
    gap: 12px;
    padding-bottom: 0;
  }

  .footer-middle {
    gap: 16px;
    padding: 16px 0;
  }

  .footer-bottom {
    padding-top: 22px;
  }

  .footer-socials-wrapper {
    align-items: flex-start;
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    gap: 5px;
  }

  .footer-top-description {
    text-align: left;
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    font-size: 18px;
  }

  .footer-common-separator {
    display: none;
  }

  .logo-link {
    padding-bottom: 16px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;

    img {
      width: 210px;
    }
  }

  .footer-bottom-links {
    flex-direction: column;
    gap: 0;

    & > * {
      font-size: 12px;
    }
  }

  .sitis-support span {
    font-size: 14px;
  }
}

</style>
