<template>
  <div class="menu">
    <div class="menu-wrapper">
      <div class="container">
        <div class="menu-logo-wrapper">
          <img
            src="~/assets/img/png/header/logo.png"
            alt="logo"
            class="menu-logo"
          >
        </div>
        <div class="menu-phone-wrapper">
          <a :href="getCleanedPhoneForHref(phone)">{{ phone }}</a>
        </div>
        <div
          v-if="menus.length"
          class="menu-nav"
        >
          <ul
            class="mobile-nav-links"
          >
            <li
              class="mobile-nav-link"
              @click="onMobileSidebarHandler"
            >
              <nuxt-link to="/">Главная</nuxt-link>
            </li>
            <li
              v-for="menu in menus"
              :key="`mobile-nav-${menu.id}`"
              class="mobile-nav-link"
              @click="onMobileSidebarHandler"
            >
              <nuxt-link
                :to="menu.path[0] !== '/' ? `/${menu.path}` : menu.path"
                class="link"
              >
                {{ menu.title }}
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IContentMenuItem } from '#sitis/internal/controllers/cms/models/ContentMenu';
import { useMobileMenu } from '~/composables/useMobileMenu';

const { menus, phone } = defineProps<{
  phone: string;
  menus: IContentMenuItem[]
}>();

const { onMobileSidebarHandler } = useMobileMenu();

</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
}

.menu-wrapper {
  background-color: #2E5300;
  padding-top: 63px;
  padding-bottom: 24px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.menu-logo {
  width: 210px;
}

.menu-logo-wrapper {
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(120 165 19 / 50%);
  margin-bottom: 16px;
}

.menu-phone-wrapper {
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(120 165 19 / 50%);
  margin-bottom: 16px;

  & > a {
    color: var(--text-white);
    font-size: 22px;
    font-style: normal;
    line-height: 100%; /* 22px */
    letter-spacing: -0.22px;
    text-transform: uppercase;
  }
}

.mobile-nav-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.mobile-nav-link {
  color: var(--text-white);
  font-family: Uniform-Pro, sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 123.48%;
}
</style>
