<template>
  <div class="wrapper">
    <div class="header-wrapper">
      <TheHeader />
    </div>
    <NuxtLoadingIndicator color="#ABDD39" />
    <NuxtLayout>
      <main
        class="main"
        :class="{'home':isHome}"
      >
        <NuxtPage />
      </main>
    </NuxtLayout>

    <div class="footer-wrapper">
      <TheFooter />
    </div>

    <template v-if="countersFooter">
      <div v-html="countersFooter" />
    </template>

    <OrderModal
      v-if="isServicePopupOpen"
    />
    <ProductModal v-if="isProductPopupOpen" />
  </div>
</template>
<script setup lang="ts">
import TheHeader from '~/components/common/TheHeader.vue';
import TheFooter from '~/components/common/TheFooter.vue';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { appConfigStore } from '#sitis/stores/modules/app-store';
import { catalogStore } from '#sitis/stores/modules/catalog-store';
import { useRoute } from '#app';

import { useServicePopup } from '~/composables/useServicePopup';
import { useProductPopup } from '~/composables/useProductPopup';

const OrderModal = defineAsyncComponent(() => import('~/components/popups/OrderModal.vue'));
const ProductModal = defineAsyncComponent(() => import('~/components/popups/ProductModal.vue'));

const { isServicePopupOpen } = useServicePopup();
const { isProductPopupOpen } = useProductPopup();

const storeCatalog = catalogStore();
const cmsStore = cmsContentStore();
const configStore = appConfigStore();

const $route = useRoute();

await useAsyncData('initProjectSettings', async () => await Promise.all([
  cmsStore.getMenu(),
  configStore.getConfig(),
  storeCatalog.getCatalog()
]));

onServerPrefetch(async () => {
  await cmsStore.getMenu();
  await configStore.getConfig();
  await storeCatalog.getCatalog();
});

useHead({
  title: 'Ремонтно механическая мастерская',
  htmlAttrs: {
    lang: 'ru'
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1 user-scalable=no' },
    { name: 'description', content: 'my content' }
  ],
  link: [
    { rel: 'icon', type: 'image/svg+xml', href: 'https://api.rmm-urfo.ru/storage/0_mask-group_620314b8.svg' }
  ]
});

const isHome = computed(() => $route.fullPath === '/');
const countersFooter = computed(() => configStore?.$state?.config?.counters?.footer || '');

</script>

<style lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.main {
  padding: 0 0 40px;
  min-height: 100%;
  flex: 1 0 auto;
  padding-bottom: 0;

  &.home {
    padding: 0 0 40px;
    padding-bottom: 0;
  }
}

.footer-wrapper {
  width: 100%;
  flex: 0 0 auto;
}

</style>
