const phoneFormat = (phone: string, cleaned = false) => {
  if (!phone) {
    return '';
  }

  const cleanedPhone = (phone).replace(/\D/g, '');
  const matchEight = cleanedPhone.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (!matchEight) {
    return phone;
  }

  if (matchEight[1] === '8') {
    matchEight[1] = '7';
  }

  if (cleaned) {
    return `${matchEight[1]}${matchEight[2]}${matchEight[3]}${matchEight[4]}${matchEight[5]}`;
  }

  return `+${matchEight[1]}(${matchEight[2]}) ${matchEight[3]}-${matchEight[4]}-${matchEight[5]}`;
};

export default phoneFormat;
