import { default as _91_46_46_46cms_93wH3yUCO8ndMeta } from "/home/c84330/rmm.na4u.ru/rmm-fronend/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93ShXOdGv243Meta } from "/home/c84330/rmm.na4u.ru/rmm-fronend/pages/catalog/[...slug].vue?macro=true";
import { default as indexdYH1egS2Z8Meta } from "/home/c84330/rmm.na4u.ru/rmm-fronend/pages/catalog/index.vue?macro=true";
import { default as indexc4exeH2kDYMeta } from "/home/c84330/rmm.na4u.ru/rmm-fronend/pages/nasi-raboty/index.vue?macro=true";
import { default as _91_46_46_46slug_93sBNNQaYZe8Meta } from "/home/c84330/rmm.na4u.ru/rmm-fronend/pages/product/[...slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93wH3yUCO8ndMeta?.name ?? "cms",
    path: _91_46_46_46cms_93wH3yUCO8ndMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93wH3yUCO8ndMeta || {},
    alias: _91_46_46_46cms_93wH3yUCO8ndMeta?.alias || [],
    redirect: _91_46_46_46cms_93wH3yUCO8ndMeta?.redirect,
    component: () => import("/home/c84330/rmm.na4u.ru/rmm-fronend/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ShXOdGv243Meta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93ShXOdGv243Meta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93ShXOdGv243Meta || {},
    alias: _91_46_46_46slug_93ShXOdGv243Meta?.alias || [],
    redirect: _91_46_46_46slug_93ShXOdGv243Meta?.redirect,
    component: () => import("/home/c84330/rmm.na4u.ru/rmm-fronend/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexdYH1egS2Z8Meta?.name ?? "catalog",
    path: indexdYH1egS2Z8Meta?.path ?? "/catalog",
    meta: indexdYH1egS2Z8Meta || {},
    alias: indexdYH1egS2Z8Meta?.alias || [],
    redirect: indexdYH1egS2Z8Meta?.redirect,
    component: () => import("/home/c84330/rmm.na4u.ru/rmm-fronend/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexc4exeH2kDYMeta?.name ?? "nasi-raboty",
    path: indexc4exeH2kDYMeta?.path ?? "/nasi-raboty",
    meta: indexc4exeH2kDYMeta || {},
    alias: indexc4exeH2kDYMeta?.alias || [],
    redirect: indexc4exeH2kDYMeta?.redirect,
    component: () => import("/home/c84330/rmm.na4u.ru/rmm-fronend/pages/nasi-raboty/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93sBNNQaYZe8Meta?.name ?? "product-slug",
    path: _91_46_46_46slug_93sBNNQaYZe8Meta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93sBNNQaYZe8Meta || {},
    alias: _91_46_46_46slug_93sBNNQaYZe8Meta?.alias || [],
    redirect: _91_46_46_46slug_93sBNNQaYZe8Meta?.redirect,
    component: () => import("/home/c84330/rmm.na4u.ru/rmm-fronend/pages/product/[...slug].vue").then(m => m.default || m)
  }
]