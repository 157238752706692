import revive_payload_client_4sVQNw7RlN from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hAQZccAnsC from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/c84330/rmm.na4u.ru/rmm-fronend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_bKSoGl1L7p from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/yandex-metrika-module-nuxt3/dist/runtime/plugin.mjs";
import plugin_client_W86eSGFqyZ from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import initialPlugin_nVOZz4WWBl from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/sitis-nuxt-3/dist/runtime/plugins/initialPlugin.mjs";
import setCanonikal_eJ3HDgKmUc from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/sitis-nuxt-3/dist/runtime/server/common/setCanonikal.mjs";
import chunk_reload_client_UciE0i6zes from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import toast_DCKv3PaVhZ from "/home/c84330/rmm.na4u.ru/rmm-fronend/node_modules/sitis-nuxt-3/dist/runtime/plugins/toast.mjs";
import customSectionClasses_wk2E3ghrcB from "/home/c84330/rmm.na4u.ru/rmm-fronend/plugins/customSectionClasses.ts";
import maska_UHaKf2z1iQ from "/home/c84330/rmm.na4u.ru/rmm-fronend/plugins/maska.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_bKSoGl1L7p,
  plugin_client_W86eSGFqyZ,
  initialPlugin_nVOZz4WWBl,
  setCanonikal_eJ3HDgKmUc,
  chunk_reload_client_UciE0i6zes,
  toast_DCKv3PaVhZ,
  customSectionClasses_wk2E3ghrcB,
  maska_UHaKf2z1iQ
]