import { defineNuxtPlugin } from '#imports';

export type SectionTypes = {
    padding_top_remove?: string | null;
    padding_bottom_remove?: string | null;
    padding_size?: string | null;
    margin_top_remove?: string | null;
    margin_bottom_remove?: string | null;
    margin_size?: string | null;
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('customSectionClasses', (cfgs: SectionTypes) => {
    const classesList = [
      'custom-section'
    ];
    if (cfgs?.padding_top_remove) {
      classesList.push('rpt');
    }
    if (cfgs?.padding_bottom_remove) {
      classesList.push('rpb');
    }
    if (cfgs?.padding_size) {
      classesList.push(cfgs?.padding_size);
    }

    return classesList.join(' ');
  });
});
