import type { Ref } from 'vue';

interface IUseMobileMenu{
    isMobileMenuOpen:Ref<boolean>;
    isMobileSidebarOpen:Ref<boolean>;
    onMenuHandler:()=>void;
    onMobileSidebarHandler:()=> void;
    closeMobileSidebar:()=> void;
}

export const useMobileMenu = ():IUseMobileMenu => {
  const isMobileMenuOpen = useState<boolean>('isMobileMenuOpen', () => false);
  const isMobileSidebarOpen = useState<boolean>('isMobileSidebarOpen', () => false);

  const onMenuHandler = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
  };

  const onMobileSidebarHandler = () => {
    isMobileSidebarOpen.value = !isMobileSidebarOpen.value;
  };

  const closeMobileSidebar = () => {
    isMobileSidebarOpen.value = false;
  };
  return {
    isMobileMenuOpen,
    isMobileSidebarOpen,

    closeMobileSidebar,
    onMenuHandler,
    onMobileSidebarHandler
  };
};
