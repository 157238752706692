<template>
  <div>
    <div
      v-if=" isFixedHeader"
      :style="{height:headerHeight + 'px'}"
    />
    <div
      id="header"
      :class="{
        'home-page':isHome,
        'opened-sidebar': isMobileSidebarOpen,
        'fixed':isFixedHeader,
      }"
      class="header-root"
    >
      <div class="container header-container">
        <div
          class="burger-btn"
          @click="openMobileMenu"
        >
          <img
            v-if="!isMobileSidebarOpen"
            src="~/assets/img/png/header/little-logo.png"
            alt="btn"
          />
          <img
            v-if="isMobileSidebarOpen"
            src="~/assets/img/svg/header/close.svg"
            alt="btn"
          />
          <img
            v-else
            src="~/assets/img/svg/header/burger-btn.svg"
            alt="btn"
          />
        </div>
        <div
          v-if="!isHome && !isFixedHeader"
          class="header-container__logo"
        >
          <img
            src="../../assets/img/png/header/logo.png"
            alt="logo"
          >
          <NuxtLink
            class="link-absolute"
            to="/"
          />
        </div>
        <div
          v-if="!isHome && isFixedHeader"
          class="header-container__logo"
        >
          <img
            src="~/assets/img/png/header/logo-black.png"
            alt="logo"
          >
          <NuxtLink
            class="link-absolute"
            to="/"
          />
        </div>
        <div class="header-container__handlers">
          <div class="header-container__bottom">
            <div class="header__nav">
              <template
                v-for="(item) in menu"
                :key="`DesktopHeaderNavItem-${item.path}`"
              >
                <HeaderNavItem
                  :is-clickable="true"
                  :item="item"
                />
              </template>
            </div>
          </div>
        </div>
        <div class="header-container__social">
          <HeaderSocials
            :phone="configList?.phone || ''"
            :telegram="configList?.telegram || ''"
            :whatsapp="configList?.whatsapp || ''"
            :is-fixed="isFixedHeader"
          />
        </div>
      </div>
    </div>

    <LazyMobileSidebar
      v-if="$viewport.isLessThan('xl')"
      :is-open="isMobileSidebarOpen"
      z-index="20"
      bg-z-index="5"
      offset-top="0px"
      bg-color="rgba(255, 255, 255, 0)"
    >
      <MobileMenu
        :phone="configList?.phone || ''"
        :menus="menu"
      />
    </LazyMobileSidebar>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useMobileMenu } from '~/composables/useMobileMenu';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { catalogStore } from '#sitis/stores/modules/catalog-store';
import useSitisCMSPage from '#sitis/composables/useSitisCMSPage';
import MobileMenu from '~/components/common/Header/MobileMenu.vue';

const $viewport = useViewport();
const HeaderSocials = defineAsyncComponent(() => import('~/components/common/Header/HeaderSocials.vue'));

const LazyMobileSidebar = defineAsyncComponent(() => import('~/components/sidebar/LazyMobileSidebar.vue'));
const { isMobileSidebarOpen, onMobileSidebarHandler } = useMobileMenu();
const cmsStore = cmsContentStore();
const { menu } = storeToRefs(cmsStore);
const $route = useRoute();
const { catalogTree } = catalogStore();

const isFixedHeader = ref<boolean>(false);
const headerHeight = ref(0);
const openMobileMenu = () => {
  onMobileSidebarHandler();
};

const appConfigStore = useAppConfigStore();
const {
  varsList,
  configList
} = storeToRefs(appConfigStore);

const { getCMSContent } = useSitisCMSPage();
await getCMSContent();

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});
const handleScroll = (event: Event) => {
  headerHeight.value = document.getElementById('header')?.offsetHeight || 0;
  const isFixedCheck = ref(((event?.target as Document).scrollingElement?.scrollTop || 0) > 300);

  if (isFixedHeader.value !== isFixedCheck.value) {
    isFixedHeader.value = isFixedCheck.value;
  }
};

const isHome = computed((): boolean => $route.fullPath === '/');
</script>

<style lang="scss">
.extra-container{
  top: 76px;
}

.header-root {
  height: 82px;
  transition: background-color .2s linear;
  position: relative;
  top: 0;
  z-index: 99;

  &.fixed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99;
    transition: top .4s;
    background-color: #fff;
    animation: header-opacity 0.8s;

    & .desktop-header-nav-item__link {
      color: var(--text-black);
    }

    & .burger-btn {
      background: #F0F4EB;
      backdrop-filter: blur(20px);
    }

  }

  &.opened-sidebar {
    background: transparent;
    animation: unset;

    & .burger-btn {
      background: rgb(0 0 0 / 20%);
      backdrop-filter: blur(20px);
    }
  }

  &.home-page:not(.fixed, .opened-sidebar) {
    position: static;
    left: auto;
    right: auto;
    top: auto;
  }

  & .desktop-header-nav-item__dropdown {
    top: calc(100% + 15px);
    left: -25px;
  }

  & .desktop-header-nav-item__link {
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    color: var(--text-white);
    font-family: Uniform-Pro, sans-serif;

    &.router-link-active {
      position: relative;

      &::before {
        content: '';
        width: 100%;
        height: 4px;
        background: var(--bg-prymary-hover);
        display: block;
        position: absolute;
        top: -23px;
        left: 0;
      }

      &::after {
        content: '';
        width: 100%;
        height: 4px;
        background: var(--bg-prymary-hover);
        display: block;
        position: absolute;
        bottom: -23px;
        left: 0;
      }
    }

    &:hover {
      color: var(--primary);
    }

    & img{
      filter: var(--primary-filter-img);
    }
  }

  & .search-input__input{
    border-radius: 10px;
    background-color: rgb(255 255 255 / 13%);
    border-color: transparent;
  }

  & input{
    color: var(--gray);

    &::placeholder{
      color: var(--gray);
    }
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.header-container__logo {
  position: relative;
  width: 158px;
}

.header-container__handlers{
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 610px;
}

.search-input__header{
  width: 100%;
}

.header-container__top {
  display: flex;
  justify-content: space-between;

  & .left {
    order: initial;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.header-container__bottom {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.header__nav {
  display: flex;
  align-items: center;
  gap: 30px;
  flex: 1;
}

.burger-btn {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 11px;
  max-height: 34px;
  min-height: 34px;
  border-radius: 7px;
  background: rgb(0 0 0 / 20%);
  backdrop-filter: blur(20px);
  gap: 9px;
}

.header-mobile__phone{
  width: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 191 63 / 35%);
  border-radius: 50%;
  display: none;

  & > img{
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(310deg) brightness(111%) contrast(100%);
  }
}

@media(max-width: 1199px) {
  .header-root {
    & .header-contacts-wrapper{
      gap: 20px 10px;
    }

    & .desktop-header-nav-item__link {
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: #DBDBDB;
      font-family: Uniform-Pro, sans-serif;
    }
  }

  .header-container__handlers{
    margin-right: 10px;
    min-width: initial;
    max-width: 500px;
  }

  .header__nav{
    gap: 20px;
  }
}

@media(max-width: 1023px) {
  .mobile-menu-top {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    flex-direction: column;
    gap: 15px;

    & > .search-input{
      width: 100%;
    }
  }

  .header-container__handlers{
    display: none;
  }

  .header-mobile__phone{
    display: flex;
  }

  .header-container__logo{
    display: none;
  }

  .header-container__bottom {
    display: none;
  }

  .header-container__top {
    align-items: center;

    & .left {
      order: 1;
    }

    & .right {
      display: none;
    }
  }

  .burger-btn {
    display: flex;
  }
}

@media(max-width: 640px) {
  .header-root {
    height: 62px;
  }
}

@keyframes header-opacity {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
