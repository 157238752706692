import phoneFormat from '~/utils/formaters/phoneFormater';

const getCleanedPhoneForHref = (phone: string) => `tel:+${phoneFormat(phone, true)}`;

const getEmailForHref = (mail: string) => `mailto:${mail}`;

const getWhatsAppPhoneForHref = (whatsAppPhone: string) => `https://wa.me/${phoneFormat(whatsAppPhone, true)}`;

const getTelegramPhoneForHref = (telegramPhone:string) => `https://t.me/${telegramPhone}`;

export {
  getCleanedPhoneForHref,
  getEmailForHref,
  getWhatsAppPhoneForHref,
  getTelegramPhoneForHref
};
